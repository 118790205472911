import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Header = ({ isSidebarVisible, setIsSidebarVisible }) => {
  const [isOpen1, setIsOpen1] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const handleButtonClick = () => {
    const element = document.getElementById("html5");
    if (element.classList.contains("layout-menu-expanded")) {
      element.classList.remove("layout-menu-expanded");
    } else {
      element.classList.add("layout-menu-expanded");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("logged_in");
    navigate("/login");
  };
  return (
    <>
      {/* Navbar */}
      <nav
        className="layout-navbar container-xxl navbar navbar-expand-lg navbar-detached align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0">
          <button
            className="nav-link px-0 me-xl-4 d-none d-xl-block"
            onClick={toggleSidebar}
          >
            {isSidebarVisible ? (
              <i className="ti ti-arrow-bar-to-left d-block ti-sm align-middle" />
            ) : (
              <i className="ti ti-arrow-bar-to-right d-block ti-sm align-middle" />
            )}
          </button>
          <button
            className="nav-link px-0 me-xl-4 d-block d-xl-none"
            onClick={handleButtonClick}
          >
            {isSidebarVisible ? (
              <i className="ti ti-arrow-bar-to-left d-block ti-sm align-middle" />
            ) : (
              <i className="ti ti-arrow-bar-to-right d-block ti-sm align-middle" />
            )}
          </button>
        </div>
        <div
          className="navbar-nav-right d-flex align-items-center"
          id="navbar-collapse"
        >
          <div className="navbar-nav align-items-center">
            <h4 className="m-0">Dashboard</h4>
          </div>
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            {/* Language */}
            <li className="nav-item dropdown-language dropdown me-2 me-xl-0">
              <button
                className="nav-link dropdown-toggle hide-arrow"
                data-bs-toggle="dropdown"
              >
                <i className="fi fi-in fis rounded-circle me-1 fs-3" />
              </button>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <button className="dropdown-item" data-language="en">
                    <i className="fi fi-us fis rounded-circle me-1 fs-3" />
                    <span className="align-middle">English</span>
                  </button>
                </li>
                <li>
                  <button className="dropdown-item" data-language="fr">
                    <i className="fi fi-fr fis rounded-circle me-1 fs-3" />
                    <span className="align-middle">French</span>
                  </button>
                </li>
                <li>
                  <button className="dropdown-item" data-language="de">
                    <i className="fi fi-de fis rounded-circle me-1 fs-3" />
                    <span className="align-middle">German</span>
                  </button>
                </li>
                <li>
                  <button className="dropdown-item" data-language="pt">
                    <i className="fi fi-pt fis rounded-circle me-1 fs-3" />
                    <span className="align-middle">Portuguese</span>
                  </button>
                </li>
              </ul>
            </li>
            {/*/ Language */}
            {/* Style Switcher */}
            <li className="nav-item me-2 me-xl-0">
              <button className="nav-link style-switcher-toggle hide-arrow">
                <i className="ti ti-md" />
              </button>
            </li>
            {/*/ Style Switcher */}
            {/* User */}
            <li className="nav-item dropdown  navbar-dropdown dropdown-user">
              <button
                className="nav-link dropdown-toggle hide-arrow"
                id="navbarDropdown1"
                onClick={toggleDropdown1}
              >
                <div className="avatar avatar-online">
                  <img
                    src="../../assets/img/avatars/1.png"
                    alt="pic"
                    className="h-auto rounded-circle"
                  />
                </div>
              </button>
              <div
                className={`dropdown-menu ${isOpen1 ? "show" : ""}`}
                aria-labelledby="navbarDropdown1"
              >
                <ul className="p-0">
                  <li>
                    <button className="dropdown-item">
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <div className="avatar avatar-online">
                            <img
                              src="../../assets/img/avatars/1.png"
                              alt="pic"
                              className="h-auto rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <span className="fw-semibold d-block">
                            Dark Prince
                          </span>
                          <small className="text-muted">Admin</small>
                        </div>
                      </div>
                    </button>
                  </li>
                  <li>
                    <div className="dropdown-divider" />
                  </li>
                  <li>
                    <button className="dropdown-item">
                      <i className="ti ti-user-check me-2 ti-sm" />
                      <span className="align-middle">My Profile</span>
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item">
                      <i className="ti ti-settings me-2 ti-sm" />
                      <span className="align-middle">Settings</span>
                    </button>
                  </li>
                  <li>
                    <div className="dropdown-divider" />
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={handleLogout}>
                      <i className="ti ti-logout me-2 ti-sm" />
                      <span className="align-middle">Log Out</span>
                    </button>
                  </li>
                </ul>
              </div>
            </li>
            {/*/ User */}
          </ul>
        </div>
      </nav>
      {/* / Navbar */}
    </>
  );
};

export default Header;
