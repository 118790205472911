import React, { useEffect, useState } from "react";
import CoachingSection from "../components/CoachingSection";
import TitleSection from "../components/TitleSection";
import ScrollToTop from "../components/ScrollToTop";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import axios from "axios";
import Preloader from "../components/Preloader";

const Coaching = () => {
  const [coachings, setCoachings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [ev, setEv] = useState("");

  const LIMIT = 12;

  useEffect(() => {
    serCoach();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    search(coachings);
    setCurrentPage(1);
  }, [ev]);

  const serCoach = async () => {
    setLoading(true);
    let { data } = await axios.get(
      `${process.env.REACT_APP_API_KEY}/coachings`
    );
    setCoachings(data);
    setLoading(false);
  };

  const search = (data) => {
    return data.filter((item) =>
      item.name.toLowerCase().includes(ev.toLowerCase())
    );
  };

  const startIndex = (currentPage - 1) * LIMIT;
  const endIndex = startIndex + LIMIT;
  return (
    <>
      <div className="page-wrapper">
        {/* Preloader */}
        <div className="preloader1" />
        {/* Main Header*/}
        <Nav />
        {/* End Main Header */}
        <TitleSection
          title=" Choose A Coaching..."
          desc="Choose Your Desired Coaching To View All Its Courses."
          setEv={setEv}
        />
        {loading ? (
          <Preloader />
        ) : (
          <CoachingSection
            coachings={search(coachings)}
            count={search(coachings).length}
            limit={LIMIT}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            startIndex={startIndex}
            endIndex={endIndex}
          />
        )}
      </div>
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default Coaching;
