import React, { useEffect, useState } from "react";
import Aside from "../components/admin/Aside";
import Header from "../components/admin/Header";
import Footer from "../components/admin/Footer";
import { Outlet, useNavigate } from "react-router-dom";

const Admin = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("logged_in")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <link rel="stylesheet" href="/assets/vendor/css/rtl/core.css" />
      {/* Layout wrapper */}
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Aside
            isSidebarVisible={isSidebarVisible}
            setIsSidebarVisible={setIsSidebarVisible}
          />
          {/* Layout container */}
          <div className="layout-page">
            <Header
              isSidebarVisible={isSidebarVisible}
              setIsSidebarVisible={setIsSidebarVisible}
            />
            {/* Content wrapper */}
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <Outlet />
              </div>
              <Footer />
              <div className="content-backdrop fade" />
            </div>
            {/* Content wrapper */}
          </div>
          {/* / Layout page */}
        </div>
        {/* Overlay */}
        <div className="layout-overlay layout-menu-toggle"></div>
        {/* Drag Target Area To SlideIn Menu On Small Screens */}
        <div className="drag-target"></div>
      </div>
      {/* / Layout wrapper */}
    </>
  );
};

export default Admin;
