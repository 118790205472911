import React from "react";

const Meow2 = () => {
  const long_demo_array = [
    10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170,
    180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320,
    330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470,
    480, 490, 500,
  ];
  const si = 10;
  const li = 30;
  return (
    <div>
      {long_demo_array.slice(si, li).map((datas, index) => {
        const adjustedIndex = index + si;
        return (
          <div key={adjustedIndex}>
            <h6>
              {datas} index-- {adjustedIndex}
            </h6>
          </div>
        );
      })}
    </div>
  );
};

export default Meow2;
