import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Aside = ({ isSidebarVisible, setIsSidebarVisible }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const location = useLocation();

  const toggleSubMenu = (menu) => {
    setActiveMenu((prevMenu) => (prevMenu === menu ? null : menu));
    setIsSidebarVisible(true);
  };

  const handleButtonClick = () => {
    const element = document.getElementById("html5");
    if (element.classList.contains("layout-menu-expanded")) {
      element.classList.remove("layout-menu-expanded");
    }
  };

  const navData = [
    {
      title: "Home",
      icon: "ti-list-numbers",
      link: "",
    },
    {
      title: "Add Coachings",
      icon: "ti-folder-plus",
      link: "add_coachings",
    },
    {
      title: "Manage Coachings",
      icon: "ti-file-settings",
      link: "manage_coachings",
    },
    {
      title: "Contact",
      icon: "ti-mail",
      link: "#",
      submenus: [
        {
          title: "Support",
          link: "/contact/support",
        },
        {
          title: "Report",
          link: "/contact/report",
        },
      ],
    },
  ];

  return (
    <>
      {/* Menu */}
      <aside
        id="layout-menu"
        className="layout-menu menu-vertical menu bg-menu-theme"
        style={{ width: isSidebarVisible ? "" : "80px" }}
      >
        <div className="app-brand demo pt-4 pb-4 mt-2">
          <button className="app-brand-link">
            <span className="app-brand-logo demo">
              <svg
                width={32}
                height={22}
                viewBox="0 0 32 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z"
                  fill="#7367F0"
                />
                <path
                  opacity="0.06"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z"
                  fill="#161616"
                />
                <path
                  opacity="0.06"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z"
                  fill="#161616"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"
                  fill="#7367F0"
                />
              </svg>
            </span>
            <span className="app-brand-text demo menu-text fw-bold">Admin</span>
          </button>
          <button
            className="layout-menu-toggle menu-link text-large ms-auto"
            onClick={handleButtonClick}
          >
            <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
          </button>
        </div>
        <div className="menu-inner-shadow" />
        <ul className="menu-inner py-1">
          {navData?.map((item, index) => {
            const isActive = location.pathname === `/admin/${item.link}`;
            const isSubMenuActive = isActive || activeMenu === index; // Check submenu activation

            return (
              <li
                className={`menu-item px-sm-2 ${
                  isSubMenuActive ? "active open" : "" // Apply active class to the whole menu item
                }`}
                key={index}
              >
                <Link
                  to={item?.link}
                  className={`menu-link ${
                    isSidebarVisible && item.submenus ? "menu-toggle" : ""
                  } ${isSidebarVisible ? "" : "w-25"}`}
                  onClick={() => toggleSubMenu(index)}
                >
                  <i className={`menu-icon tf-icons ti ${item.icon}`} />
                  <span
                    className={`${isSidebarVisible ? "d-block" : "d-none"}`}
                  >
                    {item.title}
                  </span>
                </Link>
                {isSubMenuActive && isSidebarVisible && item?.submenus && (
                  <ul className="menu-sub">
                    {item.submenus?.map((subitem, subindex) => (
                      <li className="menu-item me-2" key={subindex}>
                        <Link to={subitem.link} className="menu-link">
                          <div data-i18n="Analytics">{subitem.title}</div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </aside>
    </>
  );
};

export default Aside;
