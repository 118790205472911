import React from "react";

const TitleSection = ({ title, desc, setEv }) => {
  const searchHandle = (event) => {
    const value = event.target.value;
    setEv(value);
  };
  return (
    <>
      {/* Page Title */}
      <section className="page-title pt-3 pt-md-4">
        <div className="auto-container">
          <div className="py-3 py-md-4 py-lg-5">
            <h1 className="text-capitalize">{title}</h1>
            <h6 className="title_desc">{desc}</h6>
          </div>
          {/* Search Boxed */}
          <div className=" shadow search-boxed mt-0">
            <div className="search-box">
              {/* <form method="" autoComplete="off"> */}
              <div className="form-group">
                <input
                  type="search"
                  name="search-field"
                  autoComplete="off"
                  placeholder="What do you want to learn?"
                  onChange={searchHandle}
                  required
                />
                <button>
                  <span className="icon fa fa-search" />
                </button>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </section>
      {/*End Page Title*/}
    </>
  );
};

export default TitleSection;
