import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("logged_in")) {
      navigate("/admin");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/login`,
        {
          name,
          pass,
        }
      );

      if (!response.status === 200) {
        setError("Wrong Credentials...");
      }

      if (response.status === 200) {
        localStorage.setItem("logged_in", "true");
        navigate("/admin");
      }
    } catch (error) {
      setError("Wrong Credentials...");
    }
  };

  return (
    <>
      <link rel="stylesheet" href="/assets/vendor/css/rtl/core.css" />

      <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner py-4">
            <div className="card">
              <div className="card-body">
                <div className="app-brand justify-content-center mb-4 mt-2">
                  <button className="app-brand-link gap-2">
                    <span className="app-brand-text demo text-black fw-bold ms-1">
                      Co-Resources Admin
                    </span>
                  </button>
                </div>
                {error && <p>{error}</p>}
                <form
                  id="formAuthentication"
                  className="mb-3"
                  method="POST"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email or Username
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email-username"
                      placeholder="Enter your email or username"
                      autoFocus
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="mb-3 form-password-toggle">
                    <div className="d-flex justify-content-between">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                    </div>
                    <div className="input-group input-group-merge">
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        name="password"
                        placeholder="············"
                        aria-describedby="password"
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                      />
                      <span className="input-group-text cursor-pointer">
                        <i className="ti ti-eye-off" />
                      </span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <button
                      className="btn btn-primary bg-primary d-grid w-100 text-white"
                      type="submit"
                    >
                      Sign in
                    </button>
                  </div>
                </form>
                <div className="divider my-4">
                  <div className="divider-text">or</div>
                </div>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-icon btn-label-facebook me-3">
                    <i className="tf-icons fa-brands fa-facebook-f fs-5" />
                  </button>
                  <button className="btn btn-icon btn-label-google-plus me-3">
                    <i className="tf-icons fa-brands fa-google fs-5" />
                  </button>
                  <button className="btn btn-icon btn-label-twitter">
                    <i className="tf-icons fa-brands fa-twitter fs-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
