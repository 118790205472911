import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import CoachingCourses from "../components/CoachingCourses";
import TitleSection from "../components/TitleSection";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import Preloader from "../components/Preloader";

const CoachingCourse = () => {
  const [co, setCo] = useState([]);
  const [count, setCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [ev, setEv] = useState("");

  const LIMIT = 12;
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  useEffect(() => {
    getCo();
  }, [currentPage]);

  useEffect(() => {
    if (ev !== "") {
      getSearch();
    } else {
      getCo();
    }
  }, [ev]);

  const getCo = async () => {
    setLoading(true);
    let { data } = await axios.get(
      `${process.env.REACT_APP_API_KEY}/courses/${id}`,
      {
        params: {
          page: currentPage,
          size: LIMIT,
        },
      }
    );
    setCo(data.result);
    setCount(data.count);
    setLoading(false);
  };

  const getSearch = async () => {
    setLoading(true);
    let { data } = await axios.get(`${process.env.REACT_APP_API_KEY}/search1`, {
      params: {
        cname: id,
        squery: encodeURIComponent(ev),
      },
    });
    setCo(data.result);
    setCount(data.count);
    setLoading(false);
  };

  let d = [];
  d = id.split("_");

  return (
    <>
      <div className="page-wrapper">
        {/* Preloader */}
        <div className="preloader1" />
        {/* Main Header*/}
        <Nav />
        {/* End Main Header */}
        <TitleSection title="Browse Courses" setEv={setEv} />
        {loading ? (
          <Preloader />
        ) : (
          <CoachingCourses
            id={id}
            co={co}
            d={d}
            count={count}
            limit={LIMIT}
            page={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}

        <Footer />
      </div>
      {/*End pagewrapper*/}
      {/*Scroll to top*/}
      <ScrollToTop />
    </>
  );
};

export default CoachingCourse;
