import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Preloader from "./Preloader";

const TopicSection = () => {
  const [coaching, setCoaching] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    serCoach();
  }, []);

  const serCoach = async () => {
    setLoading(true);
    let { data } = await axios.get(
      `${process.env.REACT_APP_API_KEY}/coachings`
    );
    setCoaching(data);
    setLoading(false);
  };
  return (
    <>
      <section className="topics-section pt-5">
        <div
          className="patern-layer-one paroller"
          data-paroller-factor="0.40"
          data-paroller-factor-lg="0.20"
          data-paroller-type="foreground"
          data-paroller-direction="vertical"
          style={{ backgroundImage: "url(/images/icons/icon-1.png)" }}
        />
        <div
          className="patern-layer-two paroller"
          data-paroller-factor="0.40"
          data-paroller-factor-lg="-0.20"
          data-paroller-type="foreground"
          data-paroller-direction="vertical"
          style={{ backgroundImage: "url(/images/icons/icon-2.png)" }}
        />
        <div className="auto-container">
          <div className="sec-title centered">
            <h2>Choose a Coaching...</h2>
            <div className="text">
              Select the desired coaching which courses you want to study.
            </div>
          </div>
          {loading ? (
            <Preloader />
          ) : (
            <>
              <div className="row clearfix">
                {coaching?.slice(0, 8)?.map((datas, index) => {
                  let d = [];
                  d = datas?.name?.split("_");
                  return (
                    <div
                      key={index}
                      className="topic-block col-lg-3 col-md-4 col-sm-6 d-flex justify-content-center cp"
                    >
                      <div onClick={() => navigate(`/coaching/${datas?.dName}`)}>
                        <div className="inner-box d-flex flex-column justify-content-between w-100 h-100 shadow">
                          <div className="icon-box">
                            <div className="overlay-link">
                              <span className="icon flaticon-open-book" />
                            </div>
                          </div>
                          <h5 className="text-break text-dark opacity-75 text-capitalize">
                            {d[1] ? d[1] : d[0]}
                          </h5>
                          <div className="text">
                            Click Here To View Courses Of This Coaching.
                          </div>
                          <div className="clearfix">
                            <div className="pull-left">
                              <div className="lectures">
                                {datas?.count} Courses
                              </div>
                            </div>
                            <div className="pull-right">
                              <div className="hours">15+ Hours</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                ;
              </div>
            </>
          )}
          <div className="btn-box text-center">
            <button
              onClick={() => navigate(`/coaching`)}
              className="theme-btn btn-style-three"
            >
              <span className="txt">
                See All Coachings <i className="fa fa-angle-right" />
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default TopicSection;
