import React from "react";

const Footer = () => {
  return (
    <>
      {/* Footer */}
      <footer className="content-footer footer bg-footer-theme">
        <div className="container-xxl">
          <div className="footer-container d-flex align-items-center justify-content-between py-2 flex-column">
            <div>
              Made with ❤️ by
              <button className="fw-semibold ms-1">Ryuzaki</button>
            </div>
          </div>
        </div>
      </footer>
      {/* / Footer */}
    </>
  );
};

export default Footer;
