import React from "react";
import { Link } from "react-router-dom";

const CallToAction = () => {
  return (
    <>
      <section
        className="call-to-action-section-two"
        style={{ backgroundImage: "url(images/background/3.png)" }}
      >
        <div className="auto-container">
          <div className="content">
            <h2>Ready to get started?</h2>
            <div className="text">
              The key to success is to appreciate how people learn, understand
              the thought process that goes into instructional design,<br></br>
              what works well, and a range of different ways of achieving goals.
            </div>
            <div className="buttons-box">
              <Link to="/" className="theme-btn btn-style-one">
                <span className="txt">
                  Get Stared <i className="fa fa-angle-right" />
                </span>
              </Link>
              <Link to="/coaching" className="theme-btn btn-style-two">
                <span className="txt">
                  All Courses <i className="fa fa-angle-right" />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CallToAction;
