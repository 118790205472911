import React from "react";

const Preloader1 = () => {
  return (
    <>
      <div className="row py-sm-4 gy-3 gy-sm-0 sk-meow text-center mx-auto">
        <div className="col">
          <div className="sk-fold sk-primary">
            <div className="sk-fold-cube" />
            <div className="sk-fold-cube" />
            <div className="sk-fold-cube" />
            <div className="sk-fold-cube" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Preloader1;
