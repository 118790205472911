// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function HomeSwiper() {
  return (
    <Swiper
      modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      className="swiper-container swiper-container-horizontal swiper swiper-card-advance-bg"
    >
      <SwiperSlide>
        <div className="row">
          <div className="col-12">
            <h5 className="text-white mb-0 mt-2">Website Analytics</h5>
            <small>Total 28.5% Conversion Rate</small>
          </div>
          <div className="row">
            <div className="col-lg-7 col-md-9 col-12 order-2 order-md-1">
              <h6 className="text-white mt-0 mt-md-3 mb-3">Traffic</h6>
              <div className="row">
                <div className="col-6">
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex mb-4 align-items-center">
                      <p className="mb-0 fw-semibold me-2 website-analytics-text-bg">
                        28%
                      </p>
                      <p className="mb-0">Sessions</p>
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <p className="mb-0 fw-semibold me-2 website-analytics-text-bg">
                        1.2k
                      </p>
                      <p className="mb-0">Leads</p>
                    </li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex mb-4 align-items-center">
                      <p className="mb-0 fw-semibold me-2 website-analytics-text-bg">
                        3.1k
                      </p>
                      <p className="mb-0">Page Views</p>
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <p className="mb-0 fw-semibold me-2 website-analytics-text-bg">
                        12%
                      </p>
                      <p className="mb-0">Conversions</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-3 col-12 order-1 order-md-2 my-4 my-md-0 text-center">
              <img
                src="../../assets/img/illustrations/card-website-analytics-1.png"
                alt="Website Analytics"
                width={170}
                className="card-website-analytics-img"
              />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="row">
          <div className="col-12">
            <h5 className="text-white mb-0 mt-2">Website Analytics</h5>
            <small>Total 28.5% Conversion Rate</small>
          </div>
          <div className="col-lg-7 col-md-9 col-12 order-2 order-md-1">
            <h6 className="text-white mt-0 mt-md-3 mb-3">Spending</h6>
            <div className="row">
              <div className="col-6">
                <ul className="list-unstyled mb-0">
                  <li className="d-flex mb-4 align-items-center">
                    <p className="mb-0 fw-semibold me-2 website-analytics-text-bg">
                      12h
                    </p>
                    <p className="mb-0">Spend</p>
                  </li>
                  <li className="d-flex align-items-center mb-2">
                    <p className="mb-0 fw-semibold me-2 website-analytics-text-bg">
                      127
                    </p>
                    <p className="mb-0">Order</p>
                  </li>
                </ul>
              </div>
              <div className="col-6">
                <ul className="list-unstyled mb-0">
                  <li className="d-flex mb-4 align-items-center">
                    <p className="mb-0 fw-semibold me-2 website-analytics-text-bg">
                      18
                    </p>
                    <p className="mb-0">Order Size</p>
                  </li>
                  <li className="d-flex align-items-center mb-2">
                    <p className="mb-0 fw-semibold me-2 website-analytics-text-bg">
                      2.3k
                    </p>
                    <p className="mb-0">Items</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-3 col-12 order-1 order-md-2 my-4 my-md-0 text-center">
            <img
              src="../../assets/img/illustrations/card-website-analytics-2.png"
              alt="Website Analytics"
              width={170}
              className="card-website-analytics-img"
            />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="row">
          <div className="col-12">
            <h5 className="text-white mb-0 mt-2">Website Analytics</h5>
            <small>Total 28.5% Conversion Rate</small>
          </div>
          <div className="col-lg-7 col-md-9 col-12 order-2 order-md-1">
            <h6 className="text-white mt-0 mt-md-3 mb-3">Revenue Sources</h6>
            <div className="row">
              <div className="col-6">
                <ul className="list-unstyled mb-0">
                  <li className="d-flex mb-4 align-items-center">
                    <p className="mb-0 fw-semibold me-2 website-analytics-text-bg">
                      268
                    </p>
                    <p className="mb-0">Direct</p>
                  </li>
                  <li className="d-flex align-items-center mb-2">
                    <p className="mb-0 fw-semibold me-2 website-analytics-text-bg">
                      62
                    </p>
                    <p className="mb-0">Referral</p>
                  </li>
                </ul>
              </div>
              <div className="col-6">
                <ul className="list-unstyled mb-0">
                  <li className="d-flex mb-4 align-items-center">
                    <p className="mb-0 fw-semibold me-2 website-analytics-text-bg">
                      890
                    </p>
                    <p className="mb-0">Organic</p>
                  </li>
                  <li className="d-flex align-items-center mb-2">
                    <p className="mb-0 fw-semibold me-2 website-analytics-text-bg">
                      1.2k
                    </p>
                    <p className="mb-0">Campaign</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-3 col-12 order-1 order-md-2 my-4 my-md-0 text-center">
            <img
              src="../../assets/img/illustrations/card-website-analytics-3.png"
              alt="Website Analytics"
              width={170}
              className="card-website-analytics-img"
            />
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}
