import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import TitleSection from "../components/TitleSection";
import Nav from "../components/Nav";
import Preloader from "../components/Preloader";
import CoachingCourses from "../components/CoachingCourses";

const CourseTopics = () => {
  const [co, setCo] = useState([]);
  const [ev, setEv] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;
  const { cid, tid } = useParams();

  useEffect(() => {
    getCo();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    search(co);
    setCurrentPage(1);
  }, [ev]);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  let currentPageData = co.slice(startIndex, endIndex);

  const getCo = async () => {
    setLoading(true);
    let { data } = await axios.get(
      `${process.env.REACT_APP_API_KEY}/combo/${cid}/${tid}`
    );
    console.log(data.filteredArray);
    setCo(data.filteredArray);
    setLoading(false);
  };

  const search = (data) => {
    let x = data.filter((item) => {
      const title = item.title && item.title.toLowerCase();
      const name = item.name && item.name.toLowerCase();
      const evLower = ev.toLowerCase();

      return (
        (title && title.includes(evLower)) || (name && name.includes(evLower))
      );
    });
    let y = x.length;
    return { x, y };
  };

  let d = [];
  d = cid.split("_");

  return (
    <>
      <div className="page-wrapper">
        {/* Preloader */}
        <div className="preloader1" />
        {/* Main Header*/}
        <Nav />
        {/* End Main Header */}
        <TitleSection title="Courses" setEv={setEv} />
        {loading ? (
          <Preloader />
        ) : (
          <CoachingCourses
            tid={tid}
            id={cid}
            co={search(co).x.reverse().slice(startIndex, endIndex)}
            d={d}
            count={search(co).y}
            limit={pageSize}
            page={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}

        <Footer />
      </div>
      {/*End pagewrapper*/}
      {/*Scroll to top*/}
      <ScrollToTop />
    </>
  );
};

export default CourseTopics;
