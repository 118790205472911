import React from "react";

const Pagination = ({ setCurrentPage, page, limit, total }) => {
  const pageNumbers = [];

  for (let j = page - 3; j <= page + 3; j++) {
    if (j < 1) continue;
    if (j > Math.ceil(total / limit)) break;

    pageNumbers.push(j);
  }

  return (
    <>
      <div className="styled-pagination text-center">
        <ul className="clearfix">
          {page !== 1 && (
            <li
              className="prev shadow-sm border border-black border-opacity-25"
              onClick={() => setCurrentPage(page - 1)}
            >
              <a className="text-dark">
                <span className="fa fa-angle-left" />
              </a>
            </li>
          )}
          {pageNumbers.map((pages, index) => (
            <li
              key={index}
              onClick={() => setCurrentPage(pages)}
              className={`shadow-sm border border-black border-opacity-25 ${
                page === pages ? "active" : ""
              }`}
            >
              <a className="text-dark">{pages}</a>
            </li>
          ))}
          {page !== Math.ceil(total / limit) && (
            <li
              className="next shadow-sm border border-black border-opacity-25"
              onClick={() => setCurrentPage(page + 1)}
            >
              <a className="text-dark">
                <span className="fa fa-angle-right" />
              </a>
            </li>
          )}
        </ul>
        <span>
          Page {page} of {Math.ceil(total / limit)}
        </span>
      </div>
    </>
  );
};

export default Pagination;
