import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="main-footer pt-5">
        {/* Pattern Layer */}
        <div
          className="pattern-layer paroller"
          data-paroller-factor="0.60"
          data-paroller-factor-lg="0.20"
          data-paroller-type="foreground"
          data-paroller-direction="vertical"
          style={{ backgroundImage: "url(/images/icons/icon-1.png)" }}
        />
        <div
          className="pattern-layer-two"
          data-paroller-factor="0.60"
          data-paroller-factor-lg="0.20"
          data-paroller-type="foreground"
          data-paroller-direction="vertical"
          style={{ backgroundImage: "url(/images/icons/icon-3.png)" }}
        />
        <div className="auto-container">
          {/* Widgets Section */}
          <div className="widgets-section">
            <div className="row clearfix">
              {/* Big Column */}
              <div className="big-column col-lg-6 col-md-12 col-sm-12">
                <div className="row clearfix">
                  {/*Footer Column*/}
                  <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget logo-widget">
                      <div className="logo">
                        <a>
                          <h4>
                            <span className="text-gold">Co</span>
                            <span className="text-cyan">Resources</span>
                          </h4>
                        </a>
                      </div>
                      <div className="text">
                        This site is made for personal use only. We do not
                        support or promote piracy.
                      </div>
                    </div>
                  </div>
                  {/*Footer Column*/}
                  <div className="footer-column col-lg-5 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h4>About Us</h4>
                      <ul className="links-widget">
                        <li>
                          <a>Afficiates</a>
                        </li>
                        <li>
                          <a>Partners</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* Big Column */}
              <div className="big-column col-lg-6 col-md-12 col-sm-12">
                <div className="row clearfix">
                  {/*Footer Column*/}
                  <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h4>Resource</h4>
                      <ul className="links-widget">
                        <li>
                          <a>Privacy Policy</a>
                        </li>
                        <li>
                          <a>Support Area</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/*Footer Column*/}
                  <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h4>Quick Links</h4>
                      <ul className="links-widget">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/coaching">Coachings</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright text-center p-1 text-bg-secondary">
          Copyright © 2023 Co-Resources
        </div>
      </footer>
    </>
  );
};

export default Footer;
